import React from "react"
import { connect } from "react-redux"
/*import Link from 'gatsby-link'*/
import { navigate } from "gatsby"
import GoTrue from "gotrue-js"
import get from "lodash/get"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"
import ScrollAnimation from "react-animate-on-scroll"
import RegisterForm from "../components/RegisterForm/RegisterForm"
import { modifyUser } from "./../actions"
const netlifyIdentity = require("netlify-identity-widget")

const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000"
const API_URL = process.env.NODE_ENV === "development" ? `http://${LOCAL_FUNC}` : `https://${NETLIFY_FUNC}`;

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

class RegistrationNewIndex extends React.Component {
  constructor(data) {
    super(data)
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    const currentUser = auth.currentUser()
    this.setState({ user: currentUser })
    this.navigateUser(currentUser)
  }

  navigateUser = user => {
    if (user === null) {
      navigate("/register-new/")
      return
    }
    let shouldNavToAdmin = false
    Object.keys(user.app_metadata).forEach(item => {
      if (item === "roles") {
        user.app_metadata.roles.find(role => {
          if (role === "admin") {
            shouldNavToAdmin = true
            return
          }
        })
      }
    })
    if (shouldNavToAdmin) {
      navigate("/admin/")
      return
    } else {
      fetch(`${API_URL}/queryForTeam?teamId=${user.id}`, {
        mode: 'no-cors'
      })
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              const teamData = data.data.filter(
                entry => entry[0] === user.id
              )[0] //first element
              if (teamData !== undefined && teamData !== null) {
                navigate("/team/")
                return
              } else {
                //stay in current route
                return
              }
            })
          } else {
            response.json().then(error => {
              console.log(
                `Request failed - Status code ${response.status}\nError:`,
                error
              )
              // navigate("/register-new/") //loops!
            })
          }
        })
        .catch(err => {
          console.log(`Fetch error: ${err}`)
          // navigate("/register-new/") //loops!
        })
    }
  }

  render() {
    const { user } = this.state
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const allSections = get(this, "props.data.allContentfulBlogPost.edges")
    const hero = allSections.filter(
      a => a.node.tags && a.node.tags.includes("Hero")
    )[0]
    if (user) {
      return (
        <Layout>
          <Helmet title={`Create A Team | ${siteTitle}`} />
          <PageHero hero={hero.node} user={user} />
          <div className="container-fluid">
            <div className="wrapper register">
              <RegisterForm user={user} />
            </div>
          </div>
        </Layout>
      )
    } else {
      return <React.Fragment></React.Fragment>
    }
  }
}

const mapStateToProps = state => ({
  reduxUser: state.user
})

const mapDispatchToProps = () => {
  return {
    modifyUser
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(RegistrationNewIndex)

export const pageQuery = graphql`
  query createTeamQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { tags: { eq: "Register" } }
      sort: { fields: [sort], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            sizes(maxWidth: 1920, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
